import styled from 'styled-components'
import {
	media,
	containerMaxWidth,
	containerLargeMaxWidth,
	containerContentMaxWidth,
} from '../../../constants/media/media'
import { FC } from 'react'
import { Flex, Box } from 'rebass/styled-components'

export const PageContainer = styled.div`
	max-width: 100%;
	padding: 0 2rem;

	${media.mobileLarge`
		padding: 0 4rem;
	`};

	${media.tabletOnly`
		padding: 0 8rem;
	`};

	${media.desktopSmall`
		padding: 0 8rem;
	`};

	${media.containerBreakpoint`
		max-width: ${containerMaxWidth}px;
		padding: 0;
		margin: 0 auto;
	`};
`

export const PageContainerLarge = styled.div`
	${media.containerLargeMaxWidth`
		max-width: ${containerLargeMaxWidth}px;
		margin: 0 auto;
	`}
`

export const PageContainerContent = styled.div`
	padding: 0 4rem;

	${media.containerContentMaxWidth`
		max-width: calc(${containerContentMaxWidth}px - 8rem);
		margin: 0 auto;
	`}
`

type ColumnsProps = {
	gapless?: boolean
	flexWrap?: 'wrap' | 'nowrap'
	gap?: string
	width?: string
}

export const Columns: FC<ColumnsProps> = ({ gapless, flexWrap, gap, ...props }) => {
	let rowGap = gapless ? '0' : '-1.5rem'
	if (gap) rowGap = gap

	return <Flex {...props} mx={rowGap} flexWrap={flexWrap || 'wrap'} />
}

export const Row = Columns

type ColumnProps = {
	mobile?: string | number
	mobileMedium?: string | number
	mobileLarge?: string | number
	tablet?: string | number
	desktopSmall?: string | number
	desktopLarge?: string | number
	retina?: string | number
	gapless?: string | number
	flex?: string | number
	width?: string | number
	gap?: string
}

export const Column: FC<ColumnProps> = ({
	mobile,
	mobileMedium,
	mobileLarge,
	tablet,
	desktopSmall,
	desktopLarge,
	retina,
	gapless,
	flex,
	width,
	gap,
	...props
}) => {
	const breakpoints = [
		'100%',
		mobile || '100%',
		mobileMedium || mobile || '100%',
		mobileLarge || mobileMedium || mobile || '100%',
		tablet || mobileLarge || mobileMedium || mobile || '100%',
		desktopSmall || tablet || mobileLarge || mobileMedium || mobile || '100%',
		desktopLarge || desktopSmall || tablet || mobileLarge || mobileMedium || mobile || '100%',
		retina || desktopLarge || desktopSmall || tablet || mobileLarge || mobileMedium || mobile || '100%',
	]

	let columnGap = gapless ? 0 : '1.5rem'
	if (gap) columnGap = gap
	const defaultFlex =
		mobile || mobileMedium || mobileLarge || tablet || desktopSmall || desktopLarge || retina ? 'none' : '1 1 auto'

	return <Box width={width || breakpoints} {...props} px={columnGap} mb={columnGap} flex={flex || defaultFlex} />
}
