// @ts-ignore
import styled, { css, keyframes } from 'styled-components'
import { styleParams, StyleParams } from 'helpers/styleHelpers/styleHelpers'
import { media } from 'constants/media/media'

const spin = keyframes`
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		transform: rotate(180deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		transform: rotate(360deg);
	}
`

export interface ButtonProps extends StyleParams {
	primary?: boolean
	secondary?: boolean
	ternary?: boolean
	disabled?: boolean
	isLoading?: boolean
	block?: boolean
	ghost?: boolean
	mt?: string
	mb?: string
	small?: boolean
	clipText?: boolean
	mobilePL?: string
	mobilePR?: string
	noFill?: boolean
}

export const Button = styled.button<ButtonProps>`
	position: relative;
	display: ${({ block }) => (block ? 'block' : 'inline-block')};
	width: ${({ block }) => (block ? '100%' : 'auto')};
	height: 4.8rem;
	max-width: 100%;
	padding: 0 4rem;
	margin-top: ${({ mt }) => mt || '0'};
	margin-bottom: ${({ mb }) => mb || '0'};
	color: ${({ theme }) => theme.colors.textDark};
	background-color: #fff;
	font-family: ${({ theme }) => theme.fonts.fontFamily};
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 4.2rem;
	border-radius: 6rem;
	border: 2px solid ${({ theme }) => theme.colors.borderLight};
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
	cursor: pointer;
	transition-property: color, background-color, background, border, transform, opacity;
	transition-duration: 150ms;
	${styleParams};

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}

	svg {
		width: 1.8rem;
		transform: translateY(3px);
		fill: ${({ theme }) => theme.colors.gray};

		&:first-child {
			margin-right: 1.5rem;
			margin-left: -1.5rem;
		}

		&:last-child {
			margin-left: 1.5rem;
			margin-right: -2rem;
		}
	}

	${({ primary, theme, noFill }) =>
		primary &&
		css`
			background-color: ${theme.colors.primary};
			border-color: ${theme.colors.primary};

			svg {
				fill: ${theme.colors.primary};
			}

			&:hover {
				background-color: ${noFill ? theme.colors.primary : theme.colors.primaryDark};
				color: #fff;
			}
		`};

	${({ secondary, theme, noFill }) =>
		secondary &&
		css`
			background-color: ${theme.colors.secondary};
			color: ${theme.colors.secondary};
			border-color: ${theme.colors.secondary};

			svg {
				fill: ${theme.colors.secondary};
			}

			&:hover {
				background-color: ${noFill ? theme.colors.secondary : theme.colors.secondaryDark};
				color: #fff;
			}
		`};

	${({ ternary, theme, noFill }) =>
		ternary &&
		css`
			background-color: ${theme.colors.textDark};
			color: ${theme.colors.white};
			border-color: ${theme.colors.textDark};

			svg {
				fill: ${theme.colors.secondary};
			}

			&:hover {
				background-color: ${noFill ? theme.colors.textDark : theme.colors.blueDark};
				color: ${theme.colors.white};
			}
		`}

	${({ noFill }) =>
		noFill
			? css`
					background-color: transparent;
			  `
			: css`
					color: #fff;
					border-color: transparent;

					svg {
						fill: #fff;
					}
			  `};

	${({ small }) =>
		small &&
		css`
			height: 3.1rem;
			line-height: 2.6rem;
			padding: 0 1.7rem;
		`}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`};

	${({ clipText }) =>
		clipText &&
		css`
			overflow: initial;
			white-space: initial;
			text-overflow: initial;
		`}

	${({ isLoading, primary, secondary, theme, noFill }) =>
		isLoading &&
		css`
			opacity: 0.5;
			pointer-events: none;
			&:after {
				content: '';
				display: inline-block;
				width: 1rem;
				height: 1rem;
				margin-left: 1rem;
				animation: ${spin} 1000ms infinite;
				background: center center no-repeat;
				background-size: cover;
				border-radius: 50%;
				border-style: solid;
				border-width: 0.15rem;
				border-color: ${!noFill && (primary || secondary)
					? '#fff #fff #fff transparent'
					: `${theme.colors.primary} ${theme.colors.primary} ${theme.colors.primary} transparent`};
			}
		`}
`

export const IconButton = styled.button<ButtonProps>`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex: auto 0 0;
	width: 3.6rem;
	height: 3.6rem;
	color: ${({ theme }) => theme.colors.gray};
	background-color: #fff;
	font-size: 1.4rem;
	border-radius: 100%;
	border: 2px solid ${({ theme }) => theme.colors.gray};
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	transition-property: color, background-color, background, border, transform, opacity;
	transition-duration: 250ms;
	${styleParams};

	${media.tabletAndMobileOnly<ButtonProps>`
		padding-left: ${({ mobilePL }) => mobilePL || null};
		padding-right: ${({ mobilePR }) => mobilePR || null};
	`}

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
		border-color: ${({ theme }) => theme.colors.primary};
	}

	${({ secondary, theme }) =>
		secondary &&
		css`
			border-color: transparent;
			background-color: transparent;

			&:hover {
				border-color: transparent;
				color: #fff;
				background-color: ${theme.colors.secondary};

				svg path {
					fill: #fff;
				}
			}
		`}

	${({ primary, theme }) =>
		primary &&
		css`
			border-color: transparent;
			background-color: transparent;

			&:hover {
				border-color: transparent;
				color: #fff;
				background-color: ${theme.colors.primary};

				svg path {
					fill: #fff;
				}
			}
		`}

	${({ small }) =>
		small &&
		css`
			width: 2rem;
			height: 2rem;
			font-size: 1rem;
		`}
`

export const IconButtonLabel = styled.span`
	display: none;
`

export const ButtonGroup = styled.div`
	display: flex;

	${Button} {
		border-radius: 0;
	}

	${Button}:first-child {
		border-radius: 6rem 0 0 6rem;
		border-right-color: rgba(0, 0, 0, 0.1);
	}

	${Button}:last-child {
		border-radius: 0 6rem 6rem 0;
		border-left-color: rgba(0, 0, 0, 0.1);
	}
`

interface StrippedButtonProps {
	pl?: string
	pr?: string
	primary?: boolean
	secondary?: boolean
	fontWeight?: number
	ml?: string
	disabled?: boolean
	iconWidth?: string
}

export const StrippedButton = styled.button<StrippedButtonProps>`
	padding-left: ${({ pl }) => pl || ''};
	padding-right: ${({ pr }) => pr || ''};
	margin-left: ${({ ml }) => ml || ''};
	font-size: 1.6rem;
	font-family: ${({ theme }) => theme.fonts.fontFamily};
	color: ${({ theme }) => theme.colors.textDark};
	font-weight: ${({ fontWeight }) => fontWeight || 600};
	background-color: transparent;
	cursor: pointer;

	svg {
		width: ${({ iconWidth }) => iconWidth || '1.5rem'};

		&:first-child {
			margin-right: 1.5rem;
		}

		&:last-child {
			margin-left: 1.5rem;
		}
	}

	${({ primary, theme }) =>
		primary &&
		css`
			color: ${theme.colors.primary};

			&:hover {
				color: ${theme.colors.primaryDark};
			}
		`}

	${({ secondary, theme }) =>
		secondary &&
		css`
			color: ${theme.colors.secondary};

			&:hover {
				color: ${theme.colors.secondaryDark};
			}

			svg path {
				fill: ${theme.colors.gray};
			}
		`}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`};
`

export default Button
